import {constants} from "./constants";


export default function UseApiHelper() {

    /**
     * GET request
     * @param url The url to get from
     * @constructor
     */
    const GET = async (url: string) => {
        return await fetch(`${constants.API_URL}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
    }

    /**
     * POST request
     * @param url The url to post to
     * @param body The object to post (not json stringified!)
     * @constructor
     */
    const POST = async (url: string, body: object) => {
        return await fetch(`${constants.API_URL}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(body)
        })
    }

    return {
        GET,
        POST
    }
}
