import {useState} from "react";
import StepContent from "./step-content";

export default function Chrome() {

    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {
            text: "Stap 1: Druk rechtsboven op de drie puntjes.",
            imgSrc: "assets/images/chrome/step1.png"
        },
        {
            text: "Stap 2: Druk op \"Toevoegen aan startscherm\"",
            imgSrc: "assets/images/chrome/step2.png"
        },
        {
            text: "Stap 3: Druk op \"installeren\"",
            imgSrc: "/assets/images/chrome/step3.png"
        }
    ];

    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return <div>
        <h2>Installatie instructies Google Chrome</h2>

        <StepContent step={steps[currentStep]} currentStep={currentStep} stepsLength={steps.length} prevStep={prevStep} nextStep={nextStep} />

        <hr/>

        <a href="https://www.goedkoopprinten.nl/klantenservice" style={{marginTop: '16px'}}>Hulp nodig? Wij helpen u
            graag verder.</a>

    </div>
}
