import React, {useEffect} from 'react';
import './App.css';
import Home from "./pages/Home";
import OneSignal from 'react-onesignal';

import {createBrowserRouter, RouterProvider} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
])

function App() {

    useEffect(() => {
      // Ensure this code runs only on the client side
      if (typeof window !== 'undefined') {
        OneSignal.init({
          appId: '65906163-30ca-45e2-98b9-f75c49e145ae',
          // You can add other initialization options here
          notifyButton: {
            enable: true,
          },
          // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
          allowLocalhostAsSecureOrigin: true
        });
      }
    }, []);

    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}

export default App;
