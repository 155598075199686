import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Zoom} from "@mui/material";
import React, {forwardRef, ReactElement, useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Chrome from "./help-pages/chrome";
import Samsung from "./help-pages/samsung";
import Opera from "./help-pages/opera";
import Safari from "./help-pages/safari";
import Other from "./help-pages/other";
import BrowserSelection from "./help-pages/browser-selection";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import style from './Help.module.css'
import useLogging from "../../service/UseLogging";
import {constants} from "../../xhr/constants";

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});

export default function InstallDialog({open, onClose}: Props) {

    const {LOG_CLICK} = useLogging();

    const [selected, setSelected] = useState<string>('initial')
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    useEffect(() => {
        if (!open) {
            setSelected('')
            setDetailsActive(false)
        } else {
            // Automatically show the Safari browser selection if the OS is iOS
            if (getMobileOperatingSystem() === 'iOS') {
                handleAppleInstallClick()
                setDetailsActive(true)
            }
            else if(getMobileOperatingSystem() === 'Android') {
                LOG_CLICK("install button confirm (android)")
            }
        }
    }, [open])

    const handleInstallClick = () => {
        // @ts-ignore Track the install click event with Facebook Pixel
        if (!constants.DEBUG) fbq('track', 'SubmitApplication')
        if (deferredPrompt) {
            // @ts-ignore
            deferredPrompt.prompt();
            // @ts-ignore
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    onClose()
                }
                setDeferredPrompt(null);
            });
        } else { // when prompt is not supported by the browser redirect to browser selection page.
            setSelected('browser-selection')
        }
    };

    const handleAppleInstallClick = () => {
        LOG_CLICK("install button confirm (apple)")
        // @ts-ignore Track the install click event with Facebook Pixel
        if (!constants.DEBUG) fbq('track', 'SubmitApplication')
        setSelected('safari')
    }

    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', or 'unknown'.
     *
     * @returns {String}
     */
    function getMobileOperatingSystem(): string {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/windows phone/i.test(userAgent)) {
            // return "Windows Phone";
            return "unknown";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    const initialContent = <div className={style.installPage}>
        <img src={'/logo_512.png'} alt={''}/>
        <p style={{textAlign: 'center'}}>
            Download nu de app van Goedkoopprinten. <br/>
            Maak hieronder een keuze om de installatie te starten.
        </p>
        {getMobileOperatingSystem() === 'unknown' ? (
            <>
                <Button className={style.installButton} onClick={handleInstallClick} variant={'contained'}>
                    Installeren voor Android gebruikers
                </Button>
                <Button className={style.installButton} onClick={handleAppleInstallClick} variant={'contained'}>
                    Installeren voor Apple gebruikers
                </Button>
            </>
        ) : (
            <>
                <Button className={style.installButton} onClick={handleInstallClick} variant={'contained'}>
                    Installeer als app
                </Button>
            </>
        )}

    </div>

    const [detailsActive, setDetailsActive] = useState<boolean>(false);

    const handleContentSwitch = (selected?: string | null) => {
        if (selected === 'browser-selection' || selected === '') {
            setSelected(selected);
            setDetailsActive(false);
        } else {
            // @ts-ignore
            setSelected(selected)
            setDetailsActive(true);
        }
    }

    const content =
        selected === 'chrome' ? <Chrome/> :
            selected === 'samsung' ? <Samsung/> :
                selected === 'opera' ? <Opera/> :
                    selected === 'safari' ? <Safari/> :
                        selected === 'other' ? <Other/> :
                            selected === 'browser-selection' ? <BrowserSelection onSelection={handleContentSwitch}/> :
                                initialContent;

    return <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
        <DialogTitle>
            {detailsActive ?
                <Button sx={{position: 'absolute', left: '8px', top: '8px'}}
                        color={'inherit'}
                        onClick={() => handleContentSwitch('browser-selection')}><ArrowBackIcon/></Button>
                : null}
            <Button sx={{position: 'absolute', right: '8px', top: '8px'}} color={'inherit'}
                    onClick={onClose}><CloseIcon/></Button>
        </DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
    </Dialog>
}
