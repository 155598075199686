import {Fab} from "@mui/material";
import CameraImplementation from "../camera/CameraImplementation";
import {useEffect, useState} from "react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DownloadIcon from '@mui/icons-material/Download';
import {useSearchParams} from 'react-router-dom';

import HelpDialog from "../help/HelpDialog";
import InstallDialog from "../help/InstallDialog";
import useLogging from "../../service/UseLogging";
import {constants} from "../../xhr/constants";

export default function Home() {
    const [searchParams] = useSearchParams();
    const fromValue = searchParams.get('from');
    const install = searchParams.get('install');

    const [helpVisible, setHelpVisible] = useState(true)
    const [installDialogVisible, setInstallDialogVisible] = useState(false)

    const [cameraVisible, setCameraVisible] = useState(false)

    const {LOG_SOURCE, LOG_CLICK} = useLogging();

    const showHelp = () => {
        setHelpVisible(true)
    }

    useEffect(() => {
        setCameraVisible(!helpVisible && !installDialogVisible)
    }, [helpVisible, installDialogVisible])

    useEffect(() => {

        if (fromValue) LOG_SOURCE(fromValue)


        if (install === "1") {
            setInstallDialogVisible(true)
            setHelpVisible(false)
        } else {
            setHelpVisible(true)
            setInstallDialogVisible(false)
        }

    }, [])

    const helpCloseHandler = () => {
        setHelpVisible(false)
    }

    const installCloseHandler = () => {
        setInstallDialogVisible(false)
    }

    const handleInstallDialogClick = () => {
        LOG_CLICK("install button")
        setInstallDialogVisible(true)
    }

    const isPWA = () => {
        try {
            return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
        }
        catch (e) {
            return false;
        }
    }

    return <>
        {helpVisible && <HelpDialog onClose={helpCloseHandler}/>}
        <InstallDialog open={installDialogVisible} onClose={installCloseHandler}/>

        {cameraVisible ? <CameraImplementation/> : null}

        <Fab
            style={{
                background: '#aaaaaa',
                zIndex: 2,
                position: 'fixed',
                bottom: '16px',
                left: '16px',
                margin: 'auto'
            }}
            onClick={showHelp}><QuestionMarkIcon/></Fab>

        {!isPWA() ?
        <Fab
            style={{
                background: '#aaaaaa',
                zIndex: 2,
                position: 'fixed',
                bottom: '16px',
                right: '16px',
                margin: 'auto'
            }}
            onClick={handleInstallDialogClick}><DownloadIcon/></Fab>
        : null}
    </>

}