import {useState} from "react";
import StepContent from "./step-content";

export default function Samsung() {
        const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {
            text: "Stap 1: Druk rechtsonder op de knop met drie lijntjes.",
            imgSrc: "assets/images/samsung/step1.png"
        },
        {
            text: "Stap 2: Druk op het plus icoon met de tekst \"Toevoegen aan\".",
            imgSrc: "assets/images/samsung/step2.png"
        },
        {
            text: "Stap 3: Druk op \"Startscherm\".",
            imgSrc: "/assets/images/samsung/step3.png"
        },
        {
            text: "Stap 4: Druk daarna op \"Toevoegen\".",
            imgSrc: "/assets/images/samsung/step4.png"
        }
    ];

    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return <div>
        <h2>Installatie instructies Samsung Internet</h2>

        <StepContent step={steps[currentStep]} currentStep={currentStep} stepsLength={steps.length} prevStep={prevStep} nextStep={nextStep} />

        <hr/>

        <a href="https://www.goedkoopprinten.nl/klantenservice" style={{marginTop: '16px'}}>Hulp nodig? Wij helpen u
            graag verder.</a>

    </div>
}