import {useState} from "react";
import StepContent from "./step-content";

export default function Opera() {
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {
            text: "Stap 1: Druk rechtsboven op de drie puntjes.",
            imgSrc: "assets/images/opera/step1.png"
        },
        {
            text: "Stap 2: Druk op de plus knop \"Toevoegen aan...\".",
            imgSrc: "assets/images/opera/step2.png"
        },
        {
            text: "Stap 3: Druk op \"Startscherm\".",
            imgSrc: "/assets/images/opera/step3.png"
        },
        {
            text: "Stap 4: Controleer de naam en druk op \"Toevoegen\".",
            imgSrc: "/assets/images/opera/step4.png"
        },
        {
            text: "Stap 5: Druk onderin beeld nogmaals op \"Toevoegen\".",
            imgSrc: "/assets/images/opera/step5.png"
        },
    ];

    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return <div>
        <h2>Installatie instructies Opera</h2>

        <StepContent step={steps[currentStep]} currentStep={currentStep} stepsLength={steps.length} prevStep={prevStep}
                     nextStep={nextStep}/>

        <hr/>

        <a href="https://www.goedkoopprinten.nl/klantenservice" style={{marginTop: '16px'}}>Hulp nodig? Wij helpen u
            graag verder.</a>

    </div>
}