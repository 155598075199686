import {useState} from "react";
import StepContent from "./step-content";

export default function Safari() {
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {
            text: "Stap 1: Druk onderin op de \"deel\" knop (het vierkantje met een pijl omhoog).",
            imgSrc: "assets/images/safari/step1.png"
        },
        {
            text: "Stap 2: Kies “Zet op beginscherm”.",
            imgSrc: "assets/images/safari/step2.png"
        },
        {
            text: "Stap 3: Bevestig de naam van de app door op \"voeg toe\" te klikken.",
            imgSrc: "/assets/images/safari/step3.png"
        }
    ];

    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return <div
        style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
            height: '100%',
            flexDirection: 'column'
        }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <img src={'assets/images/safari/safari.png'}
                 style={{width: '80px', objectFit: 'cover'}}/>
            <h2 style={{textAlign: 'center', marginTop: '16px'}}>Installeren</h2>
            <p style={{textAlign: 'center'}}>Volg de stappen om de app te installeren</p>
        </div>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{
                width: '95%',
                padding: '16px',
                border: '1px solid #cdcdcd',
                borderRadius: '32px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '40px'
            }}>
                <div
                    style={{
                        width: '80%',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    1. Klik op <img src={'assets/images/safari/share.png'} style={{width: '30px'}}/> onderin beeld
                </div>
                <div
                    style={{
                        width: '80%',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                    2. Klik op "Zet op beginscherm" <img src={'assets/images/safari/add_to_home_screen.png'}
                                                         style={{marginLeft: '8px', width: '25px'}}/>
                </div>
                <br/>
            </div>
            <img className={'bounce'} src={'assets/images/green_arrow_down.png'} style={{width: '40px', position: 'absolute', bottom: '10px'}}/>

        </div>

        {/*<StepContent step={steps[currentStep]} currentStep={currentStep} stepsLength={steps.length} prevStep={prevStep}*/}
        {/*             nextStep={nextStep}/>*/}

        {/*<hr/>*/}

        {/*<a href="https://www.goedkoopprinten.nl/klantenservice" style={{marginTop: '16px'}}>Hulp nodig? Wij helpen u*/}
        {/*    graag verder.</a>*/}

    </div>
}