import {Fab, Grid} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

export default function ImageExamples() {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        zIndex: 2,
        margin: '16px 0',
    }}>
        <h2>Voorbeeld</h2>
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <img src={'assets/images/help/bad.jpeg'} style={{width: '100%'}}/>
            </Grid>
            <Grid item xs={4} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                <CloseIcon sx={{fontSize: '40px', color: 'red'}}/>
                <span style={{fontSize: '.8rem'}}>Tekens en plaatjes aan de rechterkant</span>
            </Grid>
            <Grid item xs={8}>
                <img src={'assets/images/help/good_1.jpeg'} style={{width: '100%'}}/>
            </Grid>
            <Grid item xs={4} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                <CheckIcon sx={{fontSize: '40px', color: 'green'}}/>
                <span style={{fontSize: '.8rem'}}>Tekst duidelijk in beeld</span>
            </Grid>
            <Grid item xs={8}>
                <img src={'assets/images/help/good_2.jpeg'} style={{width: '100%'}}/>
                <span style={{fontSize: '.7rem'}}>Woorden als "wifi" worden vanzelf weggehaald</span>
            </Grid>
            <Grid item xs={4} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                <CheckIcon sx={{fontSize: '40px', color: 'green'}}/>
                <span style={{fontSize: '.8rem'}}>Tekst duidelijk in beeld</span>
            </Grid>
        </Grid>

    </div>
}