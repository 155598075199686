import {Button} from "@mui/material";

interface Props {
    step: {
        text: string;
        imgSrc: string;
    };
    currentStep: number;
    stepsLength: number;
    prevStep: () => void;
    nextStep: () => void;
}

export default function StepContent({ step, currentStep, stepsLength, prevStep, nextStep }: Props) {
    return (
        <>
            <p style={{minHeight:'42px', marginBottom: '30px'}}>{step.text}</p>
            <div style={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '16px'
            }}>
                <img src={step.imgSrc} alt={`Step ${currentStep + 1}`} width={250}
                     style={{display: 'block', margin: '0 auto'}}/>
                {currentStep === stepsLength - 1 &&
                    <p>De app is nu geïnstalleerd op uw telefoon, u kunt de huidige pagina sluiten.</p>}
            </div>
            <div style={{marginBottom: '16px', display: 'flex', justifyContent: 'center', gap: '8px'}}>
                <Button variant="contained" onClick={prevStep} disabled={currentStep === 0} sx={{ width: '113px' }}>Vorige</Button>
                <Button variant="contained" onClick={nextStep} disabled={currentStep === stepsLength - 1}>Volgende</Button>
            </div>
            <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'center'}}>
                {Array.from({ length: stepsLength }).map((_, index) => (
                    <span key={index} style={{
                        margin: '0 4px',
                        padding: '4px',
                        borderRadius: '50%',
                        width: '6px',
                        height: '6px',
                        background: currentStep === index ? '#000' : '#ccc'
                    }}></span>
                ))}
            </div>
        </>
    );
}