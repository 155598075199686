import {Button, Checkbox, Dialog, DialogActions, DialogContent, Fab, Slide, Zoom} from "@mui/material";
import {forwardRef, ReactElement, useEffect, useRef, useState} from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TextualSteps from "./help-pages/textual-steps";
import ImageExamples from "./help-pages/image-examples";
import {TextareaField} from "@meierij-it/react-components-v2";

interface Props {
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});


export default function HelpDialog({onClose}: Props) {

    const [dontShowAgain, setDontShowAgain] = useState(localStorage.getItem('showHelp') === 'false')
    const [showImageExamples, setShowImageExamples] = useState(false)

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            saveDontShowAgain();
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [dontShowAgain]); // Add any dependencies your saveDontShowAgain function relies on

    const saveDontShowAgain = () => {
        localStorage.setItem('showHelp', dontShowAgain ? 'false' : 'true')
    }

    const closeHandler = () => {
        onClose()
        saveDontShowAgain()
    }

    return <Dialog open={true} onClose={closeHandler} fullScreen
                   TransitionComponent={Transition}>
        <DialogContent sx={{position: 'relative'}}>
            <img src="logo_512.png"
                 style={{position: 'absolute', width: '50px', right: '8px', top: '16px', zIndex: 1}}/>

            {/*Print content of dialog*/}
            {!showImageExamples && <TextualSteps closeHandler={closeHandler}/>}
            {showImageExamples && <ImageExamples/>}
            {showImageExamples ?
                <Button onClick={() => setShowImageExamples(false)}
                        sx={{width: '100%', color: '#fff', background: '#2e74ce'}}
                        variant={'contained'}>Terug naar stappen</Button>
                :
                <>
                    <Button onClick={() => setShowImageExamples(true)}
                            sx={{width: '100%', color: '#fff', background: '#2e74ce'}}
                            variant={'contained'}>Bekijk voorbeeld</Button>
                    <br/><br/>
                    <a href={'https://goedkoopprinten.nl'}><Button
                        sx={{width: '100%', color: '#fff', background: '#2e74ce'}}
                        variant={'contained'}>Bekijk website</Button></a>
                </>
            }
            <br/><br/>
            <Button onClick={closeHandler} sx={{width: '100%', color: '#fff', background: '#83ba3b'}}
                    variant={'contained'}>Scan printer</Button>
        </DialogContent>
    </Dialog>

}