import UseApiHelper from "../xhr/AxiosInstance";

export default function useLogging() {

    const {POST} = UseApiHelper();

    const LOG_SOURCE = (source: string) => {
        POST('log/source', {
            source: source
        }).catch(e => console.error(e))
    }


    const LOG_CONFIRM = (confirmed_string: string) => {
        POST('log/confirm', {
            confirmed_string: confirmed_string
        }).catch(e => console.error(e))
    }


    const LOG_CLICK = (element: string) => {
        POST('log/click', {
            type: element
        }).catch(e => console.error(e))
    }

    return {LOG_SOURCE, LOG_CONFIRM, LOG_CLICK}

}